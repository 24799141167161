import { handleAlertBus } from '@/helpers/alerts'
import i18n from '@/plugins/i18n'

export function onResponseHandleAlertBus(
  xhr,
  CAB = {
    type: 'error',
    _title_i18n: null,
    _description_i18n: null,
  }
) {
  const type = CAB?.type ?? 'error'
  const description = CAB?._description_i18n ?? xhr

  if (CAB?._title_i18n) {
    handleAlertBus(CAB._title_i18n, description, type)

    return
  }

  const key_title = `alerts.${xhr.config._i18nKey}.${type}.${xhr.config.method}`

  const i18n_title = i18n.t(key_title)

  if (key_title === i18n_title || i18n_title.startsWith('alerts.')) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(
        'Error: a chave i18n não está definida para relatar o erro da API.',
        `i18n: '${key_title}'`,
        xhr.config
      )
    }

    return
  }

  handleAlertBus(i18n_title, description, type)
}
