import { handleAlertBus } from '../alerts'
import i18n from '@/plugins/i18n'

export function handleCustomAlertError(error) {
  handleAlertBus(
    i18n.t('alerts.error.title'),
    error?.response?.status !== 400
      ? error?.response?.data?.message
      : i18n.t('alerts.error.description'),
    'error'
  )
}
