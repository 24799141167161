import * as libs from './libs'
import * as permissions from './permissions'

export const NestedGeneralPermissions = Object.entries(permissions).reduce(
  (acc, [key, value]) => {
    const nestedPermissions = libs.splitUnderlineToNestedObject(key, value)
    return libs.mergeObjectsRecursively(acc, nestedPermissions)
  },
  {}
)

export function getStorybookPermissions(role) {
  const permissionsByRoles = {
    admin: Object.values(permissions),
    user: Object.values(permissions).filter(
      key =>
        !['edit', 'move', 'delete'].some(permission => key.includes(permission))
    ),
    anonymous: [],
  }[role]

  return permissionsByRoles
}
