import {
  calcLanguageToApi,
  calcLanguageToLocale,
  saveLanguage,
} from '@/helpers/locale'

export default {
  provide() {
    return {
      handleChangeLanguage: this.handleChangeLanguage,
      handleSyncLanguage: this.handleSyncLanguage,
    }
  },

  created() {
    this.handleChangeLanguage(false)
  },

  beforeMount() {
    this.handleLanguageQuery()
  },

  methods: {
    hookLoginSuccess_locale(data) {
      const lang = calcLanguageToLocale(data.language)
      this?.handleChangeLanguage(lang)
    },

    handleLanguageQuery() {
      const { l: language = false } = this.$route.query
      if (!language) return

      const lang = calcLanguageToLocale(language)
      this.handleChangeLanguage(lang)
    },

    /**
     * @param {String} lang
     */
    async handleSyncLanguage(lang) {
      const language = calcLanguageToApi(lang)

      return this.$store
        .dispatch('currentUser/putAccountMe', { language })
        .then(() => {
          this.handleChangeLanguage(lang)
        })
    },

    /**
     * @param {String} lang
     */
    async handleChangeLanguage(lang) {
      const { language, reloadByNotEqSession } = saveLanguage(lang)
      this.$root.$i18n.locale = language
      this.$vuetify.lang.current = language

      if (!reloadByNotEqSession) return

      const { l: urlLang = false } = this.$route.query
      const preventUrlNotEqSession = calcLanguageToLocale(urlLang) !== language
      if (urlLang && preventUrlNotEqSession) return

      window.location.reload()
    },
  },
}
