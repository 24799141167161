import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import '@fontsource/poppins/300-italic.css'
import '@fontsource/poppins/400-italic.css'
import '@fontsource/poppins/500-italic.css'
import '@fontsource/poppins/600-italic.css'
import '@fontsource/poppins/700-italic.css'

import '@mdi/font/css/materialdesignicons.css'
import '@/assets/uicons/css/uicons-all.css'
