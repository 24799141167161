import * as _permissions from '@/helpers/ability/permissions'

export const MainPageRouterName = 'CustomSurveyPage'

const Views = {
  Login: () =>
    import(/* webpackChunkName: "LoginPage" */ '@/views/Login/Login.vue'),
  NotFound: () =>
    import(
      /* webpackChunkName: "NotFoundPage" */ '@/views/NotFound/NotFound.vue'
    ),
  MainLayout: () =>
    import(
      /* webpackChunkName: "BoxBasedLayout" */ '@/views/BoxBasedLayout/BoxBasedLayout.vue'
    ),
  Header: () => import('@/views/ReportHeader/ReportHeader.vue'),
}

const Pages = {
  Climate: {
    Root: () => import('@/pages/Climate/Climate.vue'),
    Detail: () =>
      import('@/pages/Climate/Children/ClimateDetail/ClimateDetail.vue'),
    Category: () =>
      import('@/pages/Climate/Children/CategoryDetail/CategoryDetail.vue'),
  },
  Comparison: () => import('@/pages/Comparison/Comparison.vue'),
  Participation: () => import('@/pages/Participation/Participation.vue'),
  Opinions: () => import('@/pages/Opinions/Opinions.vue'),
  Manage: () => import('@/pages/Manage/Manage.vue'),
  Settings: () => import('@/pages/Settings/Settings.vue'),
  Survey: {
    Root: () => import('@/pages/Survey/Survey.vue'),
    Init: () => import('@/pages/Survey/Children/Init/Init.vue'),
    Steps: () => import('@/pages/Survey/Children/Steps/Steps.vue'),
    Finish: () => import('@/pages/Survey/Children/Finish/Finish.vue'),
    Forbidden: () => import('@/pages/Survey/Children/Forbidden/Forbidden.vue'),
    NotFound: () => import('@/pages/Survey/Children/NotFound/NotFound.vue'),
  },
}

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Views.Login,
  },
  {
    path: '*',
    name: 'NotFound',
    component: Views.NotFound,
  },
  {
    path: '/',
    name: MainPageRouterName,
    component: Views.MainLayout,
    redirect: { name: 'ManagePage' },
    meta: {
      title: 'pageNavigation.customSurvey.routes.manage',
      requiresAuth: true,
      requiresPermission: _permissions.customSurvey,
    },
    children: [
      {
        path: 'manage',
        name: 'ManagePage',
        component: Pages.Manage,
        meta: {
          title: 'pageNavigation.customSurvey.routes.manage',
          requiresPermission: _permissions.custom_survey_list,
        },
      },
      {
        path: 'settings/:customSurveyID?',
        name: 'SettingsPage',
        component: Pages.Settings,
      },
      {
        path: 'climate/:customSurveyID',
        name: 'ClimatePage',
        redirect: { name: 'ClimateDetailPage' },
        components: {
          default: Pages.Climate.Root,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.climate',
          requiresPermission: _permissions.custom_survey_results,
        },
        children: [
          {
            path: '',
            name: 'ClimateDetailPage',
            component: Pages.Climate.Detail,
          },
          {
            path: 'categories/:categoryID',
            name: 'CategoryDetailPage',
            component: Pages.Climate.Category,
          },
        ],
      },
      {
        path: 'participations/:customSurveyID',
        name: 'ParticipationsPage',
        components: {
          default: Pages.Participation,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.participation',
          requiresPermission: [
            _permissions.custom_survey_results,
            _permissions.custom_survey_participations,
          ],
        },
      },
      {
        path: 'comparison/:customSurveyID',
        name: 'ComparisonPage',
        components: {
          default: Pages.Comparison,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.comparison',
          requiresPermission: [
            _permissions.custom_survey_results,
            _permissions.custom_survey_groups_comparison,
          ],
        },
      },
      {
        path: 'opinions/:customSurveyID',
        name: 'OpinionsPage',
        components: {
          default: Pages.Opinions,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.opinions',
          requiresPermission: _permissions.custom_survey_opinions,
        },
      },
    ],
  },
  {
    path: '/survey/:customSurveyID',
    component: Pages.Survey.Root,
    name: 'SurveyPage',
    redirect: { name: 'SurveyInit' },
    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.custom_survey_survey,
      title: 'pageNavigation.customSurvey.routes.survey',
    },
    children: [
      {
        path: 'init',
        name: 'SurveyInit',
        component: Pages.Survey.Init,
      },
      {
        path: 'steps',
        name: 'SurveySteps',
        component: Pages.Survey.Steps,
      },
      {
        path: 'finish',
        name: 'SurveyFinish',
        component: Pages.Survey.Finish,
      },
      {
        path: 'forbidden',
        name: 'SurveyForbidden',
        component: Pages.Survey.Forbidden,
      },
      {
        path: 'not-found',
        name: 'SurveyNotFound',
        component: Pages.Survey.NotFound,
      },
    ],
  },
]
