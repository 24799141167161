import Vue from 'vue'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { moduleRoutes } from './Routes'
import { beforeEachRouterEntry } from './before-each-router'

const routes = [...moduleRoutes]

/**
 * @type {import('vue-router').RouterOptions}
 */
const options = {
  mode: 'history',
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  routes,
}

const router = new VueRouter(options)

router.beforeEach(beforeEachRouterEntry)

export default router
