import Vue from 'vue'

import { setupDevtoolsPlugin } from '@vue/devtools-api'
import { definePermissionToggle } from '@/helpers/ability'
import { NestedGeneralPermissions } from '@/helpers/ability/shared'

const inspectorId = 'teamculture-ui-casl'
function setupDevtools(app) {
  setupDevtoolsPlugin(
    {
      id: 'teamculture-ui',
      label: 'Teamculture UI',
      packageName: 'teamculture-ui-backoffice',
      homepage: 'https://teamculture.com.br/',
      app,
    },
    api => {
      api.addInspector({
        id: inspectorId,
        label: 'Permissionamento CASL',
        icon: 'pets',
      })

      api.on.getInspectorTree(payload => {
        if (payload.inspectorId !== inspectorId) return

        payload.rootNodes = [
          {
            id: 'me-permissions',
            label: '/me',
            children: Object.values(NestedGeneralPermissions).map(element => {
              return {
                id: element.id,
                label: element.id,
                tags: [
                  {
                    label: payload.app.$ability.can('access', element.id)
                      ? 'active'
                      : 'inactive',
                    textColor: 0xffffff,
                    backgroundColor: 0x000000,
                  },
                ],
              }
            }),
          },
        ]
      })

      api.on.getInspectorState(payload => {
        if (payload.inspectorId !== inspectorId) return
        if (payload.nodeId === 'me-permissions') {
          payload.state = {
            '/me - permissões': payload.app.$ability.rules.map(element => {
              return {
                key: element.subject,
                id: element.subject,
                value: payload.app.$ability.can('access', element.subject),
                editable: true,
              }
            }),
          }

          return
        }

        payload.state = {
          [payload.nodeId]: payload.app.$ability.rules
            .map(element => {
              if (!element.subject.startsWith(payload.nodeId)) return

              return {
                key: element.subject,
                id: element.subject,
                value: payload.app.$ability.can('access', element.subject),
                editable: true,
              }
            })
            .filter(Boolean),
        }
      })

      api.on.editInspectorState(payload => {
        if (payload.inspectorId !== inspectorId) return

        const permission_key = payload.path.join('.')
        definePermissionToggle(permission_key, payload.state.value)
      })

      api.appRecord.rootInstance.$ability.on('updated', () => {
        api.sendInspectorTree(inspectorId)
        api.sendInspectorState(inspectorId)
      })
    }
  )
}

const install = function installVueDevtools(Vue) {
  Vue.mixin({
    beforeCreate() {
      if (this.$options.devtoolsAPI) {
        setupDevtools(this)
      }
    },
  })
}
const VueDevtoolsPlugin = { install }

Vue.use(VueDevtoolsPlugin)
