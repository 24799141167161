import { render, staticRenderFns } from "./SmartAttributes.vue?vue&type=template&id=42d753da&scoped=true"
import script from "./SmartAttributes.vue?vue&type=script&lang=js"
export * from "./SmartAttributes.vue?vue&type=script&lang=js"
import style0 from "./style.scss?vue&type=style&index=0&id=42d753da&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42d753da",
  null
  
)

export default component.exports