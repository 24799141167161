import Vue from 'vue'

export const ReportModule = {
  namespaced: true,

  state: () => ({
    id: null,
    subtitle: '',
    isRespondent: true,
    isOwner: false,
    canAccess: false,
    groupIDs: [],
    favorability: false,
    range: 10,
    apiResults: {
      data: null,
      loading: false,
    },
    demographicReportID: null,
  }),

  getters: {
    getSurveyID: state => state.id,
    getSubtitle: state => state.subtitle,
    getIsRespondent: state => state.isRespondent,
    getIsOwner: state => state.isOwner,
    getCanAccess: state => state.canAccess,
    getGroupID: state => (state.groupIDs.length > 0 ? state.groupIDs[0] : null),
    getGroupIDs: state => state.groupIDs,
    getFavorability: state => state.favorability,
    getApiResults: state => state.apiResults,
    getRange: state => state.range,
    getDemographicReportID: state => state.demographicReportID,
  },

  actions: {
    setGroupIDs({ commit }, payload) {
      commit('SET_GROUP_IDS', payload)
    },
    setFavorability({ commit }, payload) {
      commit('SET_FAVORABILITY', payload)
    },
    setApiResults({ commit }, payload) {
      commit('SET_API_RESULTS', payload)
    },
    setDefault({ commit }, payload) {
      commit('SET_DEFAULT', payload)
    },
    setRange({ commit }, payload) {
      commit('SET_RANGE', payload)
    },
    setDemographicReportID({ commit }, payload) {
      commit('SET_DEMOGRAPHIC_REPORT_ID', payload)
    },
  },

  mutations: {
    SET_GROUP_IDS(state, payload) {
      Vue.set(state, 'groupIDs', payload)
    },
    SET_FAVORABILITY(state, payload) {
      Vue.set(state, 'favorability', payload)
    },
    SET_API_RESULTS(state, payload) {
      Vue.set(state, 'apiResults', payload)
    },
    SET_DEFAULT(state, payload) {
      Vue.set(state, 'id', payload.id)
      Vue.set(state, 'subtitle', payload.subtitle)
      Vue.set(state, 'isRespondent', payload.isRespondent)
      Vue.set(state, 'isOwner', payload.isOwner)
      Vue.set(state, 'canAccess', payload.canAccess)
      Vue.set(state, 'groupIDs', [])
      Vue.set(state, 'favorability', false)
      Vue.set(state, 'range', payload.range)
      Vue.set(state, 'demographicReportID', null)
    },
    SET_RANGE(state, payload) {
      Vue.set(state, 'range', payload)
    },
    SET_DEMOGRAPHIC_REPORT_ID(state, payload) {
      Vue.set(state, 'demographicReportID', payload)
    },
  },
}
