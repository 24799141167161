import Vue from 'vue'

/**
 * @type {import('vuex').Module<any, any>}
 */
export const SurveyModule = {
  namespaced: true,

  state: () => ({
    survey: {
      id: null,
      title: null,
      description: null,
      currentQuestion: null,
      surveyAnswerID: null,
      state: null,
      questions: null,
      answer: null,
    },
  }),

  getters: {
    getSurvey: state => state.survey,
  },

  actions: {
    setSurvey({ commit }, payload) {
      commit('SET_SURVEY', payload)
    },
    setSurveyState({ commit }, payload) {
      commit('SET_SURVEY_STATE', payload)
    },
    setSurveyCurrentQuestion({ commit }, payload) {
      commit('SET_SURVEY_CURRENT_QUESTION', payload)
    },
    setSurveyQuestions({ commit }, payload) {
      commit('SET_SURVEY_QUESTIONS', payload)
    },
    setSurveyAnswer({ commit }, payload) {
      commit('SET_SURVEY_ANSWER', payload)
    },
    setSurveyAnswerID({ commit }, payload) {
      commit('SET_SURVEY_ANSWER_ID', payload)
    },
  },

  mutations: {
    SET_SURVEY(state, payload) {
      Vue.set(state, 'survey', payload)
    },
    SET_SURVEY_STATE(state, payload) {
      Vue.set(state.survey, 'state', payload)
    },
    SET_SURVEY_CURRENT_QUESTION(state, payload) {
      Vue.set(state.survey, 'currentQuestion', payload)
    },
    SET_SURVEY_QUESTIONS(state, payload) {
      Vue.set(state.survey, 'questions', payload)
    },
    SET_SURVEY_ANSWER(state, payload) {
      Vue.set(state.survey, 'answer', payload)
    },
    SET_SURVEY_ANSWER_ID(state, payload) {
      Vue.set(state.survey, 'surveyAnswerID', payload)
    },
  },
}
