import { debounce } from 'lodash'

import { onResponseValidateUnauthorized } from '@/helpers/axios/on-response-validate-unauthorized'
import { onResponseHandleAlertBus } from '@/helpers/axios/on-response-handle-alert-bus'

const defineAlertBus = response => {
  return debounce(
    (ConfigAlertBus, xhr = response) => {
      if (ConfigAlertBus?.prevent) {
        return
      }

      onResponseHandleAlertBus(xhr, ConfigAlertBus)
    },
    250,
    { maxWait: 1000 }
  )
}

/**
 * @param {import('axios').AxiosResponse} response
 */
export function onFulfilledResponse(response) {
  const _AlertBus = defineAlertBus(response)

  return Object.assign(response, {
    _AlertBus,
  })
}

/**
 * @param {Error} error
 */
export function onRejectedResponse(error) {
  onResponseValidateUnauthorized(error)

  const _AlertBus = defineAlertBus(error)
  _AlertBus()

  return Promise.reject(
    Object.assign(error, {
      _AlertBus,
    })
  )
}
