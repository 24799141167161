<template>
  <LateralMenuItem :title="$t('demographicReports.title')" :is-mini="isMini">
    <template #title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>
    <template #header-action>
      <v-btn
        class="no-padding no-margin"
        icon
        small
        data-test-demographic-reports-refresh
        @click="handleRefresh"
      >
        <i class="fi-rr-refresh refresh"></i>
      </v-btn>
    </template>

    <div v-if="loading" class="loading-container">
      <v-skeleton-loader
        type="list-item-two-line"
        height="55"
      ></v-skeleton-loader>
      <v-skeleton-loader
        type="list-item-two-line"
        height="55"
      ></v-skeleton-loader>
    </div>

    <div v-else data-test-reports-container class="reports-container">
      <div
        v-for="(report, index) in reports"
        :key="report.id"
        class="report-row"
        :data-test-demographic-report="report.status"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <p
              v-bind="attrs"
              :class="{
                'status-text-done': report.status === 'done',
                'status-text-processing': report.status === 'processing',
                'report-selected': report.id === reportSelected,
              }"
              v-on="on"
              @click="onReportClick(report.id, report.status)"
            >
              <v-icon
                data-test-demographic-reports-icon
                :class="getIconClassByReportStatus(report.status)"
                x-small
                color="neutral5"
              >
                {{ getIconByReportStatus(report.status) }}
              </v-icon>
              {{ getReportName(report, index) }}
            </p>
          </template>
          <span>{{ getReportName(report, index) }}</span>
        </v-tooltip>

        <v-btn
          v-if="_canDeleteReport && report.status === 'done'"
          icon
          small
          @click="handleDelete(report.id)"
        >
          <v-icon x-small color="neutral5">fi-rr-trash</v-icon>
        </v-btn>
      </div>
      <v-row class="no-margin">
        <v-col
          v-if="_isEmpty && !loading"
          class="empty-demographic-reports"
          data-test-demographic-reports-empty-state
        >
          <p>
            {{ $t('demographicReports.emptyMessage') }}
          </p>
        </v-col>
        <v-col
          v-if="displayShowMoreAction && !loading"
          class="show-more-column"
        >
          <div
            class="show-more-container"
            data-test-show-more-reports
            @click="handleShowMore"
          >
            {{ $t('demographicReports.showMore') }}
            <i class="fi fi-sr-caret-down"></i>
          </div>
        </v-col>
      </v-row>
    </div>
  </LateralMenuItem>
</template>

<script>
import { getDemographicReportName } from '@/helpers/data/demographic-reports'
import * as _permissions from '@/helpers/ability/permissions'

export default {
  name: 'DemographicReports',
  props: {
    isMini: {
      type: Boolean,
      default: false,
    },
    reports: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    displayShowMoreAction: {
      type: Boolean,
      default: false,
    },
    reportSelected: {
      type: String,
      default: null,
    },
  },
  computed: {
    _isEmpty() {
      return this.reports.length === 0
    },
    _canDeleteReport() {
      return this.$can(
        'access',
        _permissions.custom_survey_results_demographic_reports_delete
      )
    },
  },
  methods: {
    getReportName(report, position) {
      return getDemographicReportName(report, position)
    },
    getIconClassByReportStatus(status) {
      return status === 'done' ? 'status-icon-done' : 'status-icon-processing'
    },
    getIconByReportStatus(status) {
      return status === 'done' ? 'fi-rr-file' : 'fi-rr-time-quarter-to'
    },
    handleShowMore() {
      this.$emit('on-show-more-click', { showMore: true })
    },
    onReportClick(id, status) {
      if (status !== 'done') return
      const idToEmit = this.reportSelected === id ? '' : id
      this.$emit('on-report-click', { id: idToEmit, unselect: !idToEmit })
    },
    handleDelete(id) {
      this.$emit('on-delete-click', id)
    },
    handleRefresh() {
      this.$emit('on-refresh-click', { reset: true })
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
