import Vue from 'vue'

import { abilitiesPlugin, Can } from '@casl/vue'
import { definePermissions, ability } from '@/helpers/ability'

Vue.use(abilitiesPlugin, ability)

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Can', Can)

if (window.Cypress) {
  // be visible only during e2e tests
  window.definePermissions = definePermissions
}
