function isObject(obj) {
  return toString.call(obj) == '[object Object]'
}

function hasOwnProperty(obj, prop) {
  return Object.hasOwn(obj, prop)
}

export function mergeObjectsRecursively(...objects) {
  let mergedObject = {}

  for (const sourceObject of objects) {
    if (!isObject(sourceObject)) continue

    for (const [property, value] of Object.entries(sourceObject)) {
      if (!hasOwnProperty(sourceObject, property)) continue
      if (isObject(value)) {
        mergedObject[property] = mergeObjectsRecursively(
          mergedObject[property] || {},
          value
        )
      } else {
        mergedObject[property] = value
      }
    }
  }

  return mergedObject
}

export function splitUnderlineToNestedObject(key, value) {
  const parts = key.split('_')
  const nestedObject = {}

  function createDeepObject(parts, nestedObject, id) {
    if (parts.length > 1) {
      nestedObject[parts[0]] = {}
      createDeepObject(parts.slice(1), nestedObject[parts[0]], id)
    } else {
      nestedObject[parts[0]] = { _key: key, id }
    }
  }

  createDeepObject(parts, nestedObject, value)
  return nestedObject
}
