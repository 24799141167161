import * as _permissions from '@/helpers/ability/permissions'

export const MainPageRouterName = 'CustomSurveyPage'
import { routePage } from '@/helpers/opinions'
const Views = {
  Login: () =>
    import(/* webpackChunkName: "LoginPage" */ '@/views/Login/Login.vue'),
  NotFound: () =>
    import(
      /* webpackChunkName: "NotFoundPage" */ '@/views/NotFound/NotFound.vue'
    ),
  MainLayout: () =>
    import(
      /* webpackChunkName: "BoxBasedLayout" */ '@/views/BoxBasedLayout/BoxBasedLayout.vue'
    ),
  Header: () =>
    import(
      /* webpackChunkName: "Header" */
      '@/views/ReportHeader/ReportHeader.vue'
    ),
}

const Pages = {
  Results: {
    Root: () => import('@/pages/Results/Results.vue'),
    Detail: () =>
      import('@/pages/Results/Children/ResultsDetail/ResultsDetail.vue'),
    Category: () =>
      import('@/pages/Results/Children/CategoryDetail/CategoryDetail.vue'),
  },
  Comparison: () => import('@/pages/Comparison/Comparison.vue'),
  Participation: () => import('@/pages/Participation/Participation.vue'),
  Opinions: {
    Root: () => import('@/pages/Opinions/Opinions.vue'),
    Default: () => import('@/pages/Opinions/Children/Default/Default.vue'),
    Team: () => import('@/pages/Opinions/Children/Team/Team.vue'),
    List: () => import('@/pages/Opinions/Children/List/List.vue'),
    TeamList: () => import('@/pages/Opinions/Children/TeamList/TeamList.vue'),
  },
  Manage: () => import('@/pages/Manage/Manage.vue'),
  Settings: {
    Root: () => import('@/pages/Settings/Settings.vue'),
    General: () => import('@/pages/Settings/Children/General/General.vue'),
    Participants: () =>
      import('@/pages/Settings/Children/Participants/Participants.vue'),
    Form: () => import('@/pages/Settings/Children/Form/Form.vue'),
    ReportAccess: () =>
      import('@/pages/Settings/Children/ReportAccess/ReportAccess.vue'),
  },
  Survey: {
    Root: () => import('@/pages/Survey/Survey.vue'),
    Init: () => import('@/pages/Survey/Children/Init/Init.vue'),
    Steps: () => import('@/pages/Survey/Children/Steps/Steps.vue'),
    Finish: () => import('@/pages/Survey/Children/Finish/Finish.vue'),
    Forbidden: () => import('@/pages/Survey/Children/Forbidden/Forbidden.vue'),
    NotFound: () => import('@/pages/Survey/Children/NotFound/NotFound.vue'),
  },
}

export const moduleRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Views.Login,
  },
  {
    path: '*',
    name: 'NotFound',
    component: Views.NotFound,
  },
  {
    path: '/',
    name: MainPageRouterName,
    component: Views.MainLayout,
    redirect: { name: 'ManagePage' },
    meta: {
      title: 'pageNavigation.customSurvey.routes.manage',
      requiresAuth: true,
      requiresPermission: _permissions.customSurvey,
    },
    children: [
      {
        path: 'manage',
        name: 'ManagePage',
        component: Pages.Manage,
        meta: {
          title: 'pageNavigation.customSurvey.routes.manage',
          requiresPermission: _permissions.custom_survey_list,
        },
      },
      {
        path: 'settings',
        name: 'SettingsPageCreate',
        components: {
          default: Pages.Settings.Root,
        },
        redirect: { name: 'SettingsGeneralCreate' },
        meta: {
          title: 'pageNavigation.customSurvey.routes.settings',
          requiresPermission: _permissions.custom_survey_create,
        },
        children: [
          {
            path: 'general',
            name: 'SettingsGeneralCreate',
            component: Pages.Settings.General,
          },
          {
            path: 'participants',
            name: 'SettingsParticipantsCreate',
            component: Pages.Settings.Participants,
          },
          {
            path: 'form',
            name: 'SettingsFormCreate',
            component: Pages.Settings.Form,
          },
          {
            path: 'report-access',
            name: 'SettingsReportAccessCreate',
            component: Pages.Settings.ReportAccess,
          },
        ],
      },
      {
        path: 'settings/:customSurveyID',
        name: 'SettingsPageEdit',
        components: {
          default: Pages.Settings.Root,
        },
        redirect: { name: 'SettingsGeneralEdit' },
        meta: {
          title: 'pageNavigation.customSurvey.routes.settings',
          requiresPermission: _permissions.custom_survey_edit,
        },
        children: [
          {
            path: 'general',
            name: 'SettingsGeneralEdit',
            props: true,
            component: Pages.Settings.General,
          },
          {
            path: 'participants',
            props: true,
            name: 'SettingsParticipantsEdit',
            component: Pages.Settings.Participants,
          },
          {
            path: 'form',
            name: 'SettingsFormEdit',
            props: true,
            component: Pages.Settings.Form,
          },
          {
            path: 'report-access',
            name: 'SettingsReportAccessEdit',
            props: true,
            component: Pages.Settings.ReportAccess,
          },
        ],
      },
      {
        path: 'results/:customSurveyID',
        name: 'ResultsPage',
        redirect: { name: 'ResultsDetailPage' },
        components: {
          default: Pages.Results.Root,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.results',
          requiresPermission: [
            _permissions.custom_survey_show,
            _permissions.custom_survey_results,
          ],
        },
        children: [
          {
            path: '',
            name: 'ResultsDetailPage',
            component: Pages.Results.Detail,
          },
          {
            path: 'categories/:categoryID',
            name: 'CategoryDetailPage',
            component: Pages.Results.Category,
          },
        ],
      },
      {
        path: 'participations/:customSurveyID',
        name: 'ParticipationsPage',
        components: {
          default: Pages.Participation,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.participation',
          requiresPermission: [
            _permissions.custom_survey_show,
            _permissions.custom_survey_results,
            _permissions.custom_survey_participations,
          ],
        },
      },
      {
        path: 'comparison/:customSurveyID',
        name: 'ComparisonPage',
        components: {
          default: Pages.Comparison,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.comparison',
          requiresPermission: [
            _permissions.custom_survey_show,
            _permissions.custom_survey_results,
            _permissions.custom_survey_groups_comparison,
          ],
        },
      },
      {
        path: 'opinions/:customSurveyID',
        name: 'OpinionsPage',
        components: {
          default: Pages.Opinions.Root,
          header: Views.Header,
        },
        meta: {
          title: 'pageNavigation.customSurvey.routes.opinions',
          requiresPermission: [
            _permissions.custom_survey_show,
            _permissions.custom_survey_opinions,
          ],
        },
        redirect: { name: 'OpinionsDefaultPage' },
        children: [
          {
            meta: {
              title: 'pageNavigation.customSurvey.routes.opinionsTabs.default',
              requiresPermission: _permissions.custom_survey_opinions_me_list,
            },
            path: 'home/:selectedOpinionID?',
            name: 'OpinionsDefaultPage',
            component: Pages.Opinions.Default,
          },
          {
            meta: {
              title: 'pageNavigation.customSurvey.routes.opinionsTabs.default',
              requiresPermission: _permissions.custom_survey_opinions_me_list,
            },
            path: 'home/list/:page?',
            name: 'OpinionsListPage',
            component: Pages.Opinions.List,
            props: routePage,
          },
          {
            meta: {
              title: 'pageNavigation.customSurvey.routes.opinionsTabs.team',
              requiresPermission:
                _permissions.custom_survey_opinions_teams_list,
            },
            path: 'team/:selectedOpinionID?',
            name: 'OpinionsTeamPage',
            component: Pages.Opinions.Team,
          },
          {
            meta: {
              title: 'pageNavigation.customSurvey.routes.opinionsTabs.team',
              requiresPermission:
                _permissions.custom_survey_opinions_teams_list,
            },
            path: 'team/list/:page?',
            name: 'OpinionsTeamListPage',
            component: Pages.Opinions.TeamList,
            props: routePage,
          },
        ],
      },
    ],
  },
  {
    path: '/survey/:customSurveyID',
    component: Pages.Survey.Root,
    name: 'SurveyPage',
    redirect: { name: 'SurveyInit' },
    meta: {
      requiresAuth: true,
      requiresPermission: _permissions.custom_survey_survey,
      title: 'pageNavigation.customSurvey.routes.survey',
    },
    children: [
      {
        path: 'init',
        name: 'SurveyInit',
        component: Pages.Survey.Init,
      },
      {
        path: 'steps',
        name: 'SurveySteps',
        component: Pages.Survey.Steps,
      },
      {
        path: 'finish',
        name: 'SurveyFinish',
        component: Pages.Survey.Finish,
      },
      {
        path: 'forbidden',
        name: 'SurveyForbidden',
        component: Pages.Survey.Forbidden,
      },
      {
        path: 'not-found',
        name: 'SurveyNotFound',
        component: Pages.Survey.NotFound,
      },
    ],
  },
]
