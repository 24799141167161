<template>
  <LateralMenuItem
    :title="$t('demographicReports.smartAttributes.title')"
    :is-mini="isMini"
  >
    <template #title-icon="{ size }">
      <v-icon :size="size">mdi-flash</v-icon>
    </template>

    <v-row v-if="_isInitialLoading">
      <v-col cols="12">
        <v-skeleton-loader
          v-for="n in 10"
          :key="n"
          type="list-item"
          height="30"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <form
      v-if="!_isInitialLoading"
      class="smart-attributes"
      data-test-smart-attributes-form
      @submit.prevent="generateReport"
    >
      <template v-for="attribute in attributes">
        <!-- Com Tooltip -->
        <v-tooltip
          v-if="attribute.label.length > 5"
          :key="`${attribute.id}-tooltip`"
          bottom
        >
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <ItemSelector
                :data-test-smart-attribute="attribute.label"
                :input-config="{
                  label: attribute.label,
                  showAvatar: false,
                  clearable: true,
                }"
                :input-style="'submitMode'"
                :menu-config="{ showTabs: false }"
                :menu-options="[
                  {
                    value: attribute.id,
                    label: attribute.label,
                    type: 'listview',
                    items: attribute.values,
                  },
                ]"
                :current-value="{
                  data: selectedValues[attribute.id],
                  origin: attribute.id,
                }"
                :local-search="true"
                :watch-current="true"
                :fill-width="true"
                :multiple="true"
                @update:item="handleSelectAttribute(attribute.id, $event)"
              ></ItemSelector>
            </div>
          </template>
          <span>{{ attribute.label }}</span>
        </v-tooltip>

        <!-- Sem Tooltip -->
        <ItemSelector
          v-else
          :key="`${attribute.id}-item`"
          :data-test-smart-attribute="attribute.label"
          :input-config="{
            label: attribute.label,
            showAvatar: false,
            clearable: true,
          }"
          :input-style="'submitMode'"
          :menu-config="{ showTabs: false }"
          :menu-options="[
            {
              value: attribute.id,
              label: attribute.label,
              type: 'listview',
              items: attribute.values,
            },
          ]"
          :current-value="{
            data: selectedValues[attribute.id],
            origin: attribute.id,
          }"
          :local-search="true"
          :watch-current="true"
          :fill-width="true"
          :multiple="true"
          @update:item="handleSelectAttribute(attribute.id, $event)"
        ></ItemSelector>
      </template>

      <v-row v-if="_isLoadMoreLoading">
        <v-col cols="12">
          <v-skeleton-loader type="list-item" height="30"></v-skeleton-loader>
          <v-skeleton-loader type="list-item" height="30"></v-skeleton-loader>
          <v-skeleton-loader type="list-item" height="30"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-btn
        v-if="canLoadMore"
        class="text-none showmore-btn"
        :disabled="loading"
        text
        x-small
        @click="$emit('on-load-more')"
      >
        <span class="label">{{ $t('demographicReports.showMore') }}</span>
        <v-icon size="12">fi-rr-caret-down</v-icon>
      </v-btn>

      <v-btn
        type="submit"
        data-test-smart-attributes-generate-report
        text
        color="primary"
        :disabled="loading || _payload.length === 0"
        retain-focus-on-click
      >
        <v-icon size="10" color="white">mdi-poll</v-icon>
        <span>{{ $t('demographicReports.smartAttributes.generate') }}</span>
      </v-btn>
    </form>
  </LateralMenuItem>
</template>

<script>
export default {
  name: 'SmartAttributes',
  props: {
    isMini: {
      type: Boolean,
      default: false,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canLoadMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValues: {},
    }
  },
  computed: {
    _payload() {
      return this.getPayload()
    },
    _isInitialLoading() {
      return this.loading && this.attributes.length === 0
    },
    _isLoadMoreLoading() {
      return this.loading && this.attributes.length > 0
    },
  },
  watch: {
    attributes: {
      handler() {
        this.clearForm()
      },
      immediate: true,
    },
  },
  methods: {
    generateReport() {
      if (this._payload.length === 0) {
        this.$emit('on-generate-report', null)
        return
      }

      this.$emit('on-generate-report', this._payload)
      this.clearForm()
    },
    getPayload() {
      return Object.entries(this.selectedValues)
        .flatMap(([key, value]) => value?.map(v => ({ key, value: v?.id })))
        .filter(v => v.value)
    },
    clearForm() {
      const resetedValues = this.attributes.reduce((acc, attribute) => {
        acc[attribute.id] = []
        return acc
      }, {})

      this.$set(this, 'selectedValues', resetedValues)
    },
    handleSelectAttribute(attributeID, value) {
      this.$set(this.selectedValues, attributeID, value || [])
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
