export default {
  head() {
    return {
      htmlAttrs: {
        lang: this._currentLanguage,
      },
      title: this._currentTitle,
    }
  },

  computed: {
    _currentTitle() {
      const title = this.$route.matched.findLast(record => record?.meta?.title)

      if (!title) {
        return undefined
      }

      return `${this.$t(title.meta.title)} - TeamCulture`
    },
    _currentLanguage() {
      return this.$t('locale')
    },
  },
}
