<template>
  <LateralMenu v-if="_showLateralMenu && _hasPermissions">
    <template #default="{ isMini }">
      <ManageFilters
        v-if="showFilter('manage')"
        class="menu-item"
        :mini="isMini"
      ></ManageFilters>
      <ResultsFilters
        v-if="showFilter('results')"
        class="menu-item"
        :mini="isMini"
      ></ResultsFilters>
    </template>
  </LateralMenu>
</template>

<script>
import ManageFilters from './Partials/ManageFilters/ManageFilters.vue'
import ResultsFilters from './Partials/ResultsFilters/ResultsFilters.vue'
import * as _permissions from '@/helpers/ability/permissions'

export default {
  name: 'LateralMenuView',
  components: {
    ManageFilters,
    ResultsFilters,
  },
  computed: {
    _currentContext() {
      const routeName = this.$route.name
      const contexts = {
        ResultsDetailPage: 'results',
        CategoryDetailPage: 'results',
        ManagePage: 'manage',
      }
      return contexts[routeName] || ''
    },
    _showLateralMenu() {
      const allowedPages = ['manage', 'results']
      return allowedPages.includes(this._currentContext)
    },
    _hasPermissions() {
      if (this._currentContext === 'results') {
        return this._canCreateReport || this._canListReports
      }
      return true
    },
    _canCreateReport() {
      return this.$can(
        'access',
        _permissions.custom_survey_results_demographic_reports_create
      )
    },
    _canListReports() {
      return this.$can(
        'access',
        _permissions.custom_survey_results_demographic_reports
      )
    },
  },
  methods: {
    showFilter(context) {
      return this._currentContext === context
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
