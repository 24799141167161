import _EventBus from '@/plugins/eventBus'

export default {
  created() {
    _EventBus.$on('bus:alert', this.displayAlert)
  },

  beforeDestroy() {
    _EventBus.$off('bus:alert')
  },

  methods: {
    displayAlert(payload) {
      if (!payload) return

      this.$root.$emit('display-alert', payload)
    },
  },
}
