import { constructParams } from '@/helpers/api'
import api from '@/services/http'

export async function getSurveys(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'sortOrder',
      'sortBy',
      'status',
      'createdBy',
      'startDate',
      'endDate',
      'title',
    ],
    data
  )

  return (await api()).get('/custom-surveys', {
    params,
  })
}

export async function getSurveyReportInfo(customSurveyID) {
  return (await api()).get('/custom-surveys/:customSurveyID', {
    _params: {
      customSurveyID,
    },
  })
}

export async function getSurveyQuestions(customSurveyID) {
  return (await api()).get('/custom-surveys/:customSurveyID/survey', {
    _params: {
      customSurveyID,
    },
  })
}

export async function saveSurvey({ id, data }) {
  return (await api()).post('/custom-surveys/:customSurveyID/survey', data, {
    _params: {
      customSurveyID: id,
    },
  })
}

export async function getGroups(customSurveyID) {
  return (await api()).get('/custom-surveys/:customSurveyID/groups/tree', {
    _params: {
      customSurveyID,
    },
  })
}

export async function getResults(
  customSurveyID,
  groupID,
  categoryID,
  demographicReportID
) {
  const params = constructParams(
    ['groupID', 'categoryID', 'demographicReportID'],
    {
      groupID,
      categoryID,
      demographicReportID,
    }
  )

  return (await api()).get('/custom-surveys/:customSurveyID/results', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getHistory(
  customSurveyID,
  groupID,
  viewType,
  categoryID,
  demographicReportID
) {
  const params = constructParams(
    ['groupID', 'viewType', 'categoryID', 'demographicReportID'],
    {
      groupID,
      viewType,
      categoryID,
      demographicReportID,
    }
  )

  return (await api()).get('/custom-surveys/:customSurveyID/results/history', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getResultsNPS(
  customSurveyID,
  groupID,
  demographicReportID
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get('/custom-surveys/:customSurveyID/results/nps', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getHistoryNPS(
  customSurveyID,
  groupID,
  demographicReportID
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/results/history/nps',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function getParticipation(customSurveyID, groupID) {
  const params = constructParams(['groupID'], {
    groupID,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/results/participations',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function getParticipationHistory(customSurveyID, groupID) {
  const params = constructParams(['groupID'], {
    groupID,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/results/history/participations',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function getResultsExport(
  customSurveyID,
  groupID,
  demographicReportID
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get('/custom-surveys/:customSurveyID/results/export', {
    _params: {
      customSurveyID,
    },
    params,
    responseType: 'blob',
  })
}

export async function getComparison(customSurveyID, groupIDs) {
  const body = {}

  if (groupIDs.length) {
    body.groupIDs = [...new Set(groupIDs)]
  }

  return (await api()).post(
    '/custom-surveys/:customSurveyID/results/groups-comparison',
    body,
    {
      _params: {
        customSurveyID,
      },
    }
  )
}

export async function getComparisonExport(customSurveyID, groupIDs) {
  const body = {}

  if (groupIDs.length) {
    body.groupIDs = groupIDs
  }

  return (await api()).post(
    '/custom-surveys/:customSurveyID/results/export/groups-comparison',
    body,
    {
      _params: {
        customSurveyID,
      },
      responseType: 'blob',
    }
  )
}

export async function getDemographicReports(customSurveyID, groupID, data) {
  const params = constructParams(['groupID', 'limit', 'offset'], {
    groupID,
    ...data,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/demographic-reports',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function createDemographicReport(customSurveyID, data) {
  return (await api()).post(
    '/custom-surveys/:customSurveyID/demographic-reports',
    data,
    {
      _params: {
        customSurveyID,
      },
    }
  )
}

export async function deleteDemographicReport(customSurveyID, reportID) {
  return (await api()).delete(
    '/custom-surveys/:customSurveyID/demographic-reports/:reportID',
    {
      _params: {
        customSurveyID,
        reportID,
      },
    }
  )
}
