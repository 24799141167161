/**
 * Transforma uma string capitalizando a primeira letra de cada palavra e removendo barras.
 * @example transformString('/managment/account/me') => ManagementAccountMe
 * @example transformString('/engagement/opinions/:opinionID/messages') => EngagementOpinionsOpinionIDMessages
 *
 * @param {string} inputString - A string de entrada a ser transformada.
 * @return {string} A string transformada.
 */
export function transformString(inputString) {
  const parts = inputString.split('/')
  const transformedParts = parts.map((part, index) => {
    if (part.length === 0) {
      return ''
    }

    if (part.startsWith(':')) {
      return part.slice(1)
    }

    if (index < parts.length - 1) {
      return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() + ':'
    }

    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
  })

  return transformedParts.join('')
}

/**
 * @param {import('axios').AxiosRequestConfig} config
 */
export function onFulfilledRequest(config) {
  const _i18nKey = transformString(config.url)

  if (config.url.includes(':')) {
    const urlPath = config.url.split('/')
    const urlPatternParamsRequired = config.url
      .match(/:(\w+)/g)
      .map(param => param.slice(1))

    urlPatternParamsRequired.forEach(param => {
      if (config?._params?.[param]) {
        const paramValue = config._params[param]
        const paramIndex = urlPath.indexOf(`:${param}`)

        if (paramIndex > -1) {
          urlPath[paramIndex] = paramValue
        }
      }
    })

    config.url = urlPath.join('/')
  }

  return Object.assign(config, { _i18nKey })
}
