<template>
  <LateralMenu v-if="_showLateralMenu">
    <template #default="{ isMini }">
      <ManageFilters
        v-if="showFilter('manage')"
        class="menu-item"
        :mini="isMini"
      ></ManageFilters>
      <ClimateFilters
        v-if="showFilter('climate')"
        class="menu-item"
        :mini="isMini"
      ></ClimateFilters>
    </template>
  </LateralMenu>
</template>

<script>
import ManageFilters from './Partials/ManageFilters/ManageFilters.vue'
import ClimateFilters from './Partials/ClimateFilters/ClimateFilters.vue'

export default {
  name: 'LateralMenuView',
  components: {
    ManageFilters,
    ClimateFilters,
  },
  computed: {
    _currentContext() {
      const routeName = this.$route.name
      const contexts = {
        ClimateDetailPage: 'climate',
        CategoryDetailPage: 'climate',
        ComparisonPage: 'comparison',
        OpinionsPage: 'opinions',
        ParticipationsPage: 'participations',
        ManagePage: 'manage',
      }
      return contexts[routeName] || ''
    },
    _showLateralMenu() {
      const allowedPages = ['manage', 'climate']
      return allowedPages.includes(this._currentContext)
    },
  },
  methods: {
    showFilter(context) {
      return this._currentContext === context
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
