import i18n from '@/plugins/i18n'
import moment from 'moment'
import { calcLanguageToLocale } from '../locale'

const lang = calcLanguageToLocale(i18n.locale)
moment.locale(lang)

export function getDemographicReportName(report, position) {
  let attributesString = ''

  report.attributes.forEach((attribute, index) => {
    const isTheLastItem = report.attributes.length - 1 === index
    const separator = isTheLastItem ? '' : '|'

    attributesString += `${attribute.key}: ${attribute.value} ${separator} `
  })

  const creationDate = moment(report.date).format(i18n.t('commonDateFormat'))

  return `#${position + 1} - ${creationDate} - ${attributesString}`
}
