import Vue from 'vue'

export function sortAscending(a, b) {
  return a.date > b.date ? 1 : a.date < b.date ? -1 : 0
}

export function hideScoreSession(
  score,
  quantitativeQuestion,
  qualitativeQuestion
) {
  const isNullOrEmpty = value => !(value === '--' || !value)
  return !(
    isNullOrEmpty(score) ||
    isNullOrEmpty(quantitativeQuestion) ||
    isNullOrEmpty(qualitativeQuestion)
  )
}

export function getContinuousIndex(chunks) {
  const lastChunk = chunks[chunks.length - 1]
  const lastMessage = lastChunk?.messages[lastChunk.messages.length - 1]
  return { lastMessageID: lastMessage ? lastMessage.id + 1 : 1 }
}

export function calculateOpinionChunks(data, messages = [], append = true) {
  let opinionChunks = append ? [...messages] : []
  let { lastMessageID } = getContinuousIndex(opinionChunks)

  let opinionOwnerIdentity = { isAnonymous: true }

  const chunkMap = new Map(opinionChunks.map(chunk => [chunk.date, chunk]))

  data.forEach(message => {
    if (message.owner && !message.isAnonymous) {
      opinionOwnerIdentity = message.author
      opinionOwnerIdentity.isAnonymous = false
    }

    const chunk = chunkMap.get(message.date)

    const messageToAdd = { id: lastMessageID++, status: 'sent', ...message }

    if (chunk) {
      chunk.messages.push(messageToAdd)
    } else {
      const newChunk = {
        date: message.date,
        messages: [messageToAdd],
      }
      opinionChunks.push(newChunk)
      chunkMap.set(message.date, newChunk)
    }
  })

  opinionChunks.sort((a, b) => a.date.localeCompare(b.date))

  return { opinionChunks, opinionOwnerIdentity, lastMessageID }
}

export function syncOpinionChunks(
  selectedOpinionController,
  opinionID,
  messageID,
  newStatus
) {
  if (selectedOpinionController.data.id !== opinionID) return

  const chunks = selectedOpinionController.chunks.data
  const lastChunkIndex = chunks.length - 1
  const lastMessageChunk = chunks[lastChunkIndex].messages

  const outgoingDataIndex = lastMessageChunk.findIndex(
    data => data.id === Number(messageID - 1)
  )

  if (outgoingDataIndex !== -1) {
    Vue.set(lastMessageChunk[outgoingDataIndex], 'status', newStatus)
  }
}

export function handleSyncSelectedOpinion(
  selectedOpinionController,
  opinionID,
  props,
  incrementReplies = false
) {
  if (selectedOpinionController.data.id === opinionID) {
    syncSelectedOpinion(selectedOpinionController, props, incrementReplies)
  }
}

function syncSelectedOpinion(controller, props, incrementReplies = false) {
  const { data } = controller

  if (incrementReplies && data.totalReplies !== undefined) {
    props.totalReplies = data.totalReplies + 1
  }

  Vue.set(controller, 'data', {
    ...data,
    ...props,
  })
}

function syncConversations(controller, index, props, incrementReplies = false) {
  const item = controller.itens[index]

  if (incrementReplies) {
    props.totalReplies = item.totalReplies + 1
  }

  Vue.set(controller.itens, index, {
    ...item,
    ...props,
  })
}

export function handleSyncConversations(
  conversationsController,
  opinionID,
  props,
  incrementReplies = false
) {
  const conversationsIndex = conversationsController.itens.findIndex(
    opinion => opinion.id === opinionID
  )
  if (conversationsIndex !== -1) {
    syncConversations(
      conversationsController,
      conversationsIndex,
      props,
      incrementReplies
    )
  }
}

export const routePage = route => {
  const { page = 1 } = route.params

  return {
    page: Math.max(1, Number(page)),
  }
}
