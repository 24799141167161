import Vue from 'vue'

import { alertErrorBus } from '@/helpers/alerts'
import i18n from '@/plugins/i18n'

/**
 * https://v2.vuejs.org/v2/api/#errorHandler
 * @param {Error} error
 * @param {Vue} _vm
 * @param {string} _info
 */
// eslint-disable-next-line no-unused-vars
function errorHandler(error, _vm, _info) {
  alertErrorBus(
    i18n.t('alerts.error.title'),
    i18n.t('alerts.error.description')
  )

  if (process.env.NODE_ENV !== 'production') {
    throw error
  }
}

Vue.config.errorHandler = errorHandler
