import { projectAuth } from '@/firebase/config'

import router from '@/router'

export function onResponseValidateUnauthorized(error) {
  return new Promise(resolve => {
    if (
      !projectAuth?.currentUser ||
      !error?.response ||
      !error.response.status
    ) {
      resolve()
      return
    }

    const ForceLogoutStatus = [401]
    if (!ForceLogoutStatus.includes(error.response.status)) {
      resolve()
      return
    }

    const navigation = { name: 'Login' }
    projectAuth.signOut().then(() => {
      if (router.currentRoute.name !== navigation.name) {
        router.push(navigation)
      }

      resolve()
    })
  })
}
