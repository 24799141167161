<template>
  <LateralMenuItem
    class="manage-filters-wrapper"
    :title="$t('manageFilters.title')"
    :is-mini="mini"
  >
    <template #default>
      <ComposeForm
        form-name="managerFilters"
        :selectors="selectors"
        @compose:values="handleFilters"
      ></ComposeForm>
    </template>
  </LateralMenuItem>
</template>

<script>
import { handleCustomAlertError } from '@/helpers/data/errors'
import _EventBus from '@/plugins/eventBus'
import { getPeople } from '@/services/people'

export default {
  name: 'ManageFilters',
  props: {
    mini: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectors: {
        fields: [
          {
            id: 'status',
            _label: this.$t('manageFilters.status'),
            type: 'ItemSelector',
            options: [
              {
                value: 'status',
                label: '',
                type: 'listview',
                items: [
                  {
                    id: 'active',
                    label: this.$t('manageFilters.statusTypes.active'),
                  },
                  {
                    id: 'closed',
                    label: this.$t('manageFilters.statusTypes.closed'),
                  },
                  {
                    id: 'archived',
                    label: this.$t('manageFilters.statusTypes.archived'),
                  },
                  {
                    id: 'draft',
                    label: this.$t('manageFilters.statusTypes.draft'),
                  },
                  {
                    id: 'scheduled',
                    label: this.$t('manageFilters.statusTypes.scheduled'),
                  },
                ],
              },
            ],
            ItemSelector: {
              localSearch: true,
              multiple: false,
              inputConfig: {
                showAvatar: false,
                showIcon: false,
                clearable: true,
              },
              menuConfig: {
                showTabs: false,
              },
            },
          },
          {
            id: 'people',
            _label: this.$t('manageFilters.creator'),
            type: 'ItemSelector',
            options: [
              {
                value: 'people',
                label: '',
                type: 'listview',
                items: [],
              },
            ],
            ItemSelector: {
              localSearch: true,
              multiple: true,
              inputConfig: {
                showAvatar: true,
                showIcon: false,
                clearable: true,
                itemsToPresent: 2,
              },
              menuConfig: {
                showTabs: false,
              },
            },
          },
          {
            id: 'startDate',
            _label: this.$t('manageFilters.startDate'),
            type: 'CustomDatePicker',
            CustomDatePicker: {
              readonly: false,
              dateTime: false,
              multiple: false,
              range: false,
              inputConfig: {
                closeOnClick: true,
                rules: [],
              },
            },
          },
          {
            id: 'endDate',
            _label: this.$t('manageFilters.endDate'),
            type: 'CustomDatePicker',
            CustomDatePicker: {
              readonly: false,
              dateTime: false,
              multiple: false,
              range: false,
              inputConfig: {
                closeOnClick: true,
                rules: [],
              },
            },
          },
        ],
      },
    }
  },
  created() {
    this.handleFetch()
  },
  methods: {
    async handleFetch() {
      try {
        const response = await getPeople()
        const peopleItems = response.data.map(person => ({
          id: person.id,
          label: person.name,
          image: person.image,
        }))
        const peopleField = this.selectors.fields.find(
          field => field.id === 'people'
        )
        if (peopleField) {
          peopleField.options[0].items = peopleItems
        }
      } catch (error) {
        const field = this.selectors.fields.find(field => field.id === 'people')
        if (field) {
          field.options[0].items = []
        }
        handleCustomAlertError(error)
      }
    },
    handleFilters(values) {
      if (!values) {
        values = {}
      }
      values.status = values?.status?.[0] || ''
      values.people = values.people || []
      values.startDate = values.startDate || null
      values.endDate = values.endDate || null

      _EventBus.$emit('manage-filters:change', values)
    },
  },
}
</script>

<style scoped lang="scss" src="./style.scss" />
