<template>
  <div class="loading-wrapper" data-test-loading-app>
    <v-progress-circular
      color="primary"
      :size="50"
      indeterminate
    ></v-progress-circular>

    <div v-if="!hideContainer" class="loading-container">
      <p class="loading-text" v-text="$t('pageLogin.loading.text')"></p>
      <span
        class="loading-description"
        v-text="$t('pageLogin.loading.description')"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingParcel',
  props: {
    hideContainer: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
