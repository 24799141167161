var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LateralMenuItem',{attrs:{"title":_vm.$t('demographicReports.smartAttributes.title'),"is-mini":_vm.isMini},scopedSlots:_vm._u([{key:"title-icon",fn:function({ size }){return [_c('v-icon',{attrs:{"size":size}},[_vm._v("mdi-flash")])]}}])},[(!_vm.loading)?_c('form',{staticClass:"smart-attributes",attrs:{"data-test-smart-attributes-form":""},on:{"submit":function($event){$event.preventDefault();return _vm.generateReport.apply(null, arguments)}}},[_vm._l((_vm.attributes),function(attribute){return _c('ItemSelector',{key:attribute.id,attrs:{"data-test-smart-attribute":attribute.label,"input-config":{
        label: attribute.label,
        showAvatar: false,
        clearable: true,
      },"input-style":'submitMode',"menu-config":{
        showTabs: false,
      },"menu-options":[
        {
          value: attribute.id,
          label: attribute.label,
          type: 'listview',
          items: attribute.values,
        },
      ],"current-value":{
        data: _vm.selectedValues[attribute.id],
        origin: attribute.id,
      },"local-search":true,"watch-current":true,"fill-width":true,"multiple":true},on:{"update:item":function($event){return _vm.handleSelectAttribute(attribute.id, $event)}}})}),_c('v-btn',{attrs:{"type":"submit","data-test-smart-attributes-generate-report":"","text":"","color":"primary","disabled":_vm.loading || _vm._payload.length === 0,"retain-focus-on-click":""}},[_c('v-icon',{attrs:{"size":"10","color":"white"}},[_vm._v("mdi-poll")]),_c('span',[_vm._v(_vm._s(_vm.$t('demographicReports.smartAttributes.generate')))])],1)],2):_vm._e(),(_vm.loading)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item","height":"30"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item","height":"30"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item","height":"30"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }