export const customSurvey = 'custom-survey'

export const custom_survey_survey = 'custom-survey.survey'

export const custom_survey_list = 'custom-survey.list'

export const custom_survey_create = 'custom-survey.create'

export const custom_survey_edit = 'custom-survey.edit'

export const custom_survey_results = 'custom-survey.results'

export const custom_survey_participations = 'custom-survey.participations'

export const custom_survey_groups_comparison = 'custom-survey.groups-comparison'

export const custom_survey_opinions = 'custom-survey.opinions'

export const custom_survey_results_export_xlsx =
  'custom-survey.results.export.xlsx'

export const custom_survey_opinions_export_csv =
  'custom-survey.opinions.export.csv'

export const custom_survey_participations_xport_xlsx =
  'custom-survey.participations.export.xlsx'

export const custom_survey_groups_comparison_export_xlsx =
  'custom-survey.groups-comparison.export.xlsx'

export const custom_survey_results_demographic_reports =
  'custom-survey.results.demographic-reports'

export const custom_survey_results_demographic_reports_create =
  'custom-survey.results.demographic-reports.create'

export const custom_survey_results_demographic_reports_delete =
  'custom-survey.results.demographic-reports.delete'

export const custom_survey_opinions_rating = 'custom-survey.opinions.rating'

export const custom_survey_opinions_close = 'custom-survey.opinions.close'

export const custom_survey_opinions_teams_list =
  'custom-survey.opinions.teams.list'

export const custom_survey_opinions_me_list = 'custom-survey.opinions.me.list'

export const custom_survey_show = 'custom-survey.show'
