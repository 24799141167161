export default {
  methods: {
    hookLoginSuccess_sleek(data) {
      this.handleSleek(data)
    },

    handleSleek(user) {
      const $sleek = window?.$sleek

      if (!$sleek) {
        return
      }

      const {
        email,
        name,
        planID,
        isAdmin = false,
        accountName,
        accountPeople,
      } = user

      $sleek.setUser({
        id: email,
        name,
        mail: email,
        token: email,
        weight: isAdmin ? 10 : 1,
      })

      $sleek.addMetadata({
        account_name: accountName,
        account_people: accountPeople,
        account_planID: planID,
        person_admin: isAdmin,
      })

      $sleek.hideButton()
    },
  },
}
